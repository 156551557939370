import React from "react";
// JSX
import HeroSlider, { Slide, Nav, OverlayContainer } from "hero-slider";

import { returnResizedImageURL } from "@accrosoft-ltd/accropress-websites-components/dist/esm/imgUtils";

const BasicSlider = (props) => {
  return (
    <HeroSlider
      slidingAnimation={props.slidingAnimation || "left_to_right"}
      orientation={props.orientation || "horizontal"}
      initialSlide={props.initialSlide || 1}
      onBeforeChange={(previousSlide, nextSlide) =>
        props.onBeforeChangeFunction("onBeforeChange", previousSlide, nextSlide)
      }
      onChange={(nextSlide) =>
        props.onBeforeChangeFunction("onChange", nextSlide)
      }
      onAfterChange={(nextSlide) =>
        props.onBeforeChangeFunction("onAfterChange", nextSlide)
      }
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.33)",
      }}
      settings={{
        slidingDuration: props.slidingDuration || 190,
        slidingDelay: props.slidingDelay || 100,
        adaptiveHeight: props.adaptiveHeight || true,
        pauseOnHover: props.pauseOnHover || true,
        pauseOnFocus: props.pauseOnFocus || true,
        shouldAutoplay:
          props.shouldAutoplay != undefined ? props.shouldAutoplay : true,
        shouldDisplayButtons:
          props.shouldDisplayButtons != undefined
            ? props.shouldDisplayButtons
            : false,
        autoplayDuration: props.autoplayDuration || 5000,
        height: props.height || "100vh",
      }}
    >
      <OverlayContainer>
        {props.OverlayContainer}
        <Nav />
      </OverlayContainer>

      {props.slides.map((slide, i) => (
        <Slide
          key={i}
          background={{
            backgroundImage:
              slide.backgroundImage ||
              slide.value ||
              slide[0].backgroundImage ||
              slide[0].value,
            backgroundAttachment: slide.backgroundAttachment || "relative",
            backgroundPosition: "center",
          }}
        />
      ))}
    </HeroSlider>
  );
};

export default BasicSlider;
